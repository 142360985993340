<div class="stmnt_footer_inner">
  <div class="stmnt_footer_left">
    <div class="link_options has_padding pos_left">
      <ul>
        <li>
          <button type="button"
                  class="btn_normal btn_purple"
                  (click)="exportToCSV()">
            <span class="btn_icon"
            ><span class="icon_csv"></span
            ></span>
            <span class="btn_text">Export CSV</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>


