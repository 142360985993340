import { Injectable } from '@angular/core';
import { User } from '../../../../app/model/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivationResult } from '../../../model/activation';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthService {
  public user: User = null;
  public userLoading: boolean = false;
  public token$ = new BehaviorSubject<string | null>(null);
  public idToken$ = new BehaviorSubject<string | null>(null);

  abstract loginUser(email: string, adb2cJwtToken: string): Observable<User>;
  abstract logOut(): Observable<null | void>;
  abstract checkActiveAccount(): Observable<unknown>;
  abstract userAuthRedirect(redirectStartPage?: string): void;

  abstract activateMRO(email, adb2cJwtToken): Observable<unknown>;
  abstract validateActivationCode(activationCode: string, email: string): Observable<ActivationResult>;

  loggedIn(): boolean {
    return (this.user != null)
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser(): User {
    return this.user;
  }

  setUser(user: User) {
    this.user = user;
  }

  refreshUser(): boolean {
    if (this.loggedIn()) {
      this.userLoading = true;
      if (this.checkActiveAccount() !== undefined) {
        this.checkActiveAccount().subscribe(() => {
          this.userLoading = false;
        });
      }
      return true;
    } else {
      return false;
    }
  }

  isJWTTokenExpired(token: string): boolean {
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return tokenData.exp < currentTimestamp;
  }

  setToken(token: string | null) {
    this.token$.next(token);
  }

  setIdToken(idToken: string | null) {
    this.idToken$.next(idToken);
  }

}
