import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Observable, of } from "rxjs";
import { User } from "../../../model/user";
import { getRandomInt } from "../../../mockDB/helpers/randomNumbersHelper";
import { users } from "../../../mockDB/users";
import { ActivationResult } from "../../../model/activation";

@Injectable({
  providedIn: "root",
})
export class MockAuthService extends AuthService {
  private users: User[] = users;

  loggedIn(): boolean {
    return true;
  }

  getToken(): string {
    return "token";
  }

  setUser(user: User): void {
    this.user = user;
  }

  getUser(): User {
    if (!this.user) {
      this.user = users[getRandomInt(0, users.length)];
      this.setUser(this.user);
    }
    return this.user;
  }

  loginUser(email: string, adb2cJwtToken: string): Observable<User> {
    console.info("MockAuthService - loginUser");
    console.info("MockAuthService - email: ", email);
    console.info("MockAuthService - _adb2cJwtToken: ", adb2cJwtToken);
    return new Observable<User>((observer) => {
      observer.next(users[getRandomInt(0, this.users.length - 1)]);
      observer.complete();
    });
  }

  logOut(): Observable<void> {
    throw new Error("Method not implemented.");
  }
  checkActiveAccount(): Observable<unknown> {
    throw new Error("Method not implemented.");
  }
  userAuthRedirect(redirectStartPage?: string): void {
    throw new Error("Method not implemented.");
  }

  validateActivationCode(
    activationCode: string,
    email: string
  ): Observable<ActivationResult> {
    console.info("MockAuthService - validateActivationCode");
    console.info("MockAuthService - activationCode: ", activationCode);
    console.info("MockAuthService - email: ", email);
    return new Observable<ActivationResult>((observer) => {
      observer.next({ valid: true });
      observer.complete();
    });
  }

  activateMRO(email: string, _adb2cJwtToken: string): Observable<unknown> {
    console.info("MockAuthService - activateMRO");
    console.info("MockAuthService - email: ", email);
    console.info("MockAuthService - _adb2cJwtToken: ", _adb2cJwtToken);
    return new Observable<unknown>((observer) => {
      observer.next("MRO activated");
      observer.complete();
    });
  }
}
