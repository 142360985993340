import { EMPTY, from, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';
import { HttpService } from '../httpService/http.service';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { User } from '../../../model/user';
import { AzureAuthService } from '../../../modules/azure-auth/services/azure-auth/azure-auth.service';
import { Router } from '@angular/router';
import { ActivationResult } from '../../../model/activation';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthService extends AuthService {

  private unsubscribe = new Subject();

  constructor(
    private httpService: HttpService,
    private azureAuthService: AzureAuthService,
    private router: Router
  ) {
    super();
  }
  loginUser(email: string, adb2cJwtToken: string): Observable<User> {
    const body = { email: email, adb2cToken: adb2cJwtToken };
    return this.httpService.post('/auth/accountManagerLogin', body, false);
  }

  logOut(): Observable<null | void> {
    localStorage.clear();
    this.user = null;
    this.router.navigate(['/loggedOut']);
    return from(this.azureAuthService.logOut());
  }

  checkActiveAccount(): Observable<unknown> {
    if (!this.user) {
      return this.azureAuthService.getActiveAccount().pipe(
        takeUntil(this.unsubscribe),
        switchMap(azure => {
          if (azure) {
            localStorage.setItem('token', azure.idToken);
            this.loginUser(azure.account.username, azure.idToken).subscribe(
              user => {
                this.user = user;
              }
            );
          }
          return EMPTY;
        })
    );
    }
  }

  userAuthRedirect(redirectStartPage?: string): void{
    if (!this.userLoading) {
      this.azureAuthService.logInRedirect().pipe(
        switchMap(() => this.checkActiveAccount())
        ).subscribe();
    }
  }

  validateActivationCode(activationCode: string, email: string): Observable<ActivationResult> {
    return this.httpService.post(`/auth/validateActivationCode`, { activationCode, email }, false);
  }

  activateMRO(email: string, adb2cJwtToken: string): Observable<unknown> {
    return this.httpService.post(`/auth/activateMRO`, { email, adb2cJwtToken }, false);
  }

}
