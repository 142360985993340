<div id="card-container">
  <div class="card-section">
    <div class="card-logo">
      <img alt="logo" class="logo_image" src="../../../assets/images/Pipelinedl-ogts_logo.png">
    </div>
    <div class="card-title">
      Welcome to the Account Management Portal, log in with your registered email
    </div>
    <div class="card-content">
      <button class="adb2c-loginbutton" (click)="userAuth()">Login</button>
    </div>
  </div>
</div>
